<template>
  <div>
    <div class="header_min">
      <div class="wrap header_min_box">
        <div @click="GoIndex()" class="fl logo">
          <img src="../../assets/image/funuo.png" alt />
          孚诺大药房
        </div>
        <div class="index_search">
          <input type="text" v-model="searchTxt" placeholder="输入疾病,药品,症状" maxlength="12" />
          <button v-if="searchState == 0" @click="Gosearch()">搜索</button>
          <button v-if="searchState == 1" @click="search_Send()">搜索</button>
        </div>
        <button @click="GoAllGoods()" class="all_goods">全部商品</button>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
// import AMap from "AMap"; // 引入高德地图
// import {getShoplist} from '@/request/public'

import { search } from "@/request/index"; // 搜索
export default {
  data() {
    return {
      searchState: 0,
      searchTxt: ""
    };
  },
  created() {
    let path = this.$route.path;
    this.searchTxt = this.$route.query.search;
    if (path.indexOf("search") != -1 || path.indexOf("allGoods") != -1) {
      this.searchState = 1;
      if (this.searchTxt != undefined) {
        this.search_Send();
      }
    }
  },
  mounted() {},
  methods: {
    GoAllGoods() {
      this.$router.push({ path: "/allGoods" });
    },
    GoIndex() {
      this.$router.replace({ path: "/" });
    },
    Gosearch() {
      if (this.searchTxt == "" || this.searchTxt == undefined) {
        this.$message.error("请输入内容后在搜索");
        return;
      }
      this.$router.push({ path: "/search?search=" + this.searchTxt });
    },
    // 搜索
    search_Send() {
      if (this.searchTxt == "" || this.searchTxt == undefined) {
        this.$message.error("请输入内容后在搜索");
        return;
      }
      let path = this.$route.path;
      if (path.indexOf("allGoods") != -1) {
        this.$emit("search", this.searchTxt);
        return;
      }
	  if (path.indexOf("search") != -1) {
	    this.$emit("search", this.searchTxt);
	    return;
	  }
    }
  }
};
</script>
<style lang="less" scoped>
.index_search {
  width: 796px;
  height: 48px;
  border: 2px solid #38eec1;
  position: absolute;
  left: 52%;
  top: 50%;
  transform: translate(-50%, -50%);
  input {
    margin-top: 2px;
    margin-left: 10px;
    height: 40px;
    width: 600px;
  }
  button {
    height: 100%;
    width: 152px;
    font-size: 20px;
    float: right;
  }
}
.all_goods {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 152px;
  height: 48px;
  background: #fdb65a;
  font-size: 20px;
}
button {
  cursor: pointer;
  outline: 0px;
}
</style>
