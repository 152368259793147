import { post, get } from './http'

// 获取所有分类
export const getAllCate = data => post('api/Goods_category/getAllCate', data)

//  首页获取推荐药品
export const top_index = data => post('api/goods/top_index', data)

//  首页获取 轮播图
export const banner = data => post('api/ad/getList', data)

//  获取最新app下载链接
export const app = data => post('api/public/checkAppVersion', data)

//  首页 活动 
export const getActiveGoods = data => post('api/goods/getActiveGoods', data)

// 科室分类列表
export const getCategoryList = data => post('api/doctor/getCategoryList', data)

//  获取科室所有分类
export const getAllCategoryList = data => post('api/doctor/getAllCategoryList', data)

//  获取在线医生列表 
export const getOnlineList = data => post('api/doctor/getOnlineList', data)

//  获取医生详情
export const getinfo = data => post('api/doctor/getinfo', data)

//  添加问诊
export const tainjiawenzhen = data => post('api/inquiry/add', data)

//  获取医生待接诊数量
export const getWaittingNum = data => post("/api/doctor/getWaittingNum", data)

//  退出等待
export const leave_wait = data => post("/api/inquiry/leaveWait", data)

//  上传问诊图片
export const uploadInquiryImg = data => post("/api/prescription/picAdd", data)

//  获取药品分类 用于首页（只使用一级分类）和购买商品
export const Goods_index = data => post('api/Goods_category/index', data)

//  买赠活动列表
export const Goods_category = data => post('api/goods/gift_index', data)

//  打折活动列表
export const discount_index = data => post('api/goods/discount_index', data)

// 获取 分类或搜索的商品列表
export const Goods_indexs = data => post('api/Goods/index', data)

// 获取处方药是否可售卖状态
export const etcstatus = data => post('api/goods/etcstatus', data)

// 商品 详情
export const goods_info = data => post('api/goods/goods_info', data)

// 获取商品活动内容
export const getActives = data => post('api/goods/getActives', data)

//  查看购物车 (只显示当前门店下的数据)
export const seeCart = data => post('api/Goods_cart/seeCart', data)

// 加入购物车
export const addCart = data => post('api/Goods_cart/addCart', data)

// 商品直接下单 进入确认订单页面
export const confirm_order = data => post('api/goods_order/confirmOrder', data)

// 获取用户积分
export const getUserScore = data => post("api/User/getUserScore", data)

// 获取积分记录
export const getScoreIndex = data => post("api/Score/index", data)

// 获取用户领取的商品优惠券
export const orderGoodsCoupon = data => post('api/goods_order/order_goods_coupon', data)

// 确认订单中 获取用户通用券 和 平台券
export const orderCoupon = data => post("api/Goods_order/order_coupon", data)

// 商品直接下单 提交订单
export const submit_order = data => post('api/goods_order/submitOrder', data)

// 开始支付
export const go_pay = data => get('api/pay/go_pay', data)

// 支付查询
export const getPayStatus = data => post('api/pay/getPayStatus', data)

// 开始支付
export const getMyOrder = data => post('api/Goods_order/getMyOrder', data)

// 根据订单id 获取订单详情
export const getOrderDetails = data => post('api/Goods_order/getOrderDetails', data)

// 根据订单id 获取物流信息
export const see_post = data => post('api/goods_order/seePost', data)

//  确认收货
export const confirmGetGoods = data => post('api/Goods_order/confirmGetGoods', data)

//  评价
export const submitComment = data => post('api/Comment/submitComment', data)

//   删除订单 软删除
export const delete_order = data => post('api/Goods_order/deleteOrder', data)

//  取消订单
export const cancel_order = data => post('api/goods_order/cancelOrder', data)

//  申请退款 已支付 待发货 或者 已发货 待收货
export const applyBackMoney = data => post('api/goods_order/applyBackMoney', data)

//  余额变动
export const yuebiandong = data => post('api/finance/getList', data)

// 充值
export const recharge = data => post('api/recharge/index', data)

// 提现
export const withdrew = data => post('api/withdraw/apply', data)

// 查看购物车 (只显示当前门店下的数据)
export const Goods_cart_index = data => post('api/Goods_cart/seeCart', data)

// 加入购物车
export const add_cart = data => post('api/Goods_cart/addCart', data)

// 根据购物车id 批量删除购物车
export const delCart = data => post('api/Goods_cart/delCart', data)

// 根据购物车id 购物车商品数量+1
export const incCart = data => post('api/Goods_cart/incCart', data)

// 根据购物车id 批量删除购物车 -1 
export const desCart = data => post('api/Goods_cart/desCart', data)

// 获取商品评论统计
export const getCommentCount = data => post('api/Comment/getCommentCount', data)

// 获取 商品 评论 
export const Comment_goods = data => post('api/Comment/index', data)

// 购物车下单 确认订单界面
export const confirmCartOrder = data => post('api/Goods_order/confirmCartOrder', data)

// 购物车. 购物车下单 提交订单
export const submitCartOrder = data => post('api/goods_order/submitCartOrder', data)

// 首页搜索
export const search = data => post('api/index/search', data)

// 收藏商品
export const shoucang_good = data => post("api/Goods_favorite/add", data)

// 删除收藏
export const delete_good = data => post("api/Goods_favorite/delete", data)

// 有则删除，无则添加
export const setShoucangGood = data => post("api/Goods_favorite/active", data)

// 获取收藏商品列表
export const getFavoriteList = data => get("api/Goods_favorite/index", data)

// 获取历史购药
export const getHistoryGoodList = data => get('api/goods/historyGoods', data)

//  获取咨询医生聊天记录
export const getInquiryChatList = data => get("/api/doctor/getInquiryList", data)

// 进入商品获取商品优惠券
export const getGoodsCoupon = data => get("api/Coupon/goods_coupon", data)

